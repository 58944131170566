import React from "react"
import { connect } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../components/layout"
import {
  SubscriptionPanel,
  BreadCrumbBanner,
  // PrismicRichText,
} from "../../components/shared"
import { AccountTypesQuery } from "../../prismic/staticQueries"
const AccountTypes = ({ language }) => {
  const accountTypesData = AccountTypesQuery(language)

  return (
    <Layout>
      <BreadCrumbBanner
        heading={accountTypesData.page_heading}
        banner_image={accountTypesData.banner_image}
      />
      <div className="my-5 text-center">
        <h1 className="pt-5">{accountTypesData.page_heading[0].text}</h1>
      </div>
      <Container>
        <Row className="account-types">
          {accountTypesData.account_types.map((accountType, indexAcc) => (
            <Col md={6} className="types" key={'sdfsdf', indexAcc}>
              <div className="pricing pricing--1 shadow-lg-2">
                <div className="pricing__title">
                  <h4>{accountType.account_title[0].text}</h4>
                </div>
                <div className="pricing__price rounded">
                  {language === "en-gb" && (
                    <p>
                      <span>{accountType.account_cost[0].text[0]}</span>
                      {accountType.account_cost[0].text.substring(1)}
                      <small>/{accountType.account_cost_label[0].text}</small>
                    </p>
                  )}
                  {language !== "en-gb" && (
                    <p>
                      <small className="arabic-price">
                        {accountType.account_cost[0].text}/
                        {accountType.account_cost_label[0].text}
                      </small>
                    </p>
                  )}
                </div>
                <div className="pricing__features">
                  <ul className="bullet--list2">
                    {accountType.account_features.map((feature, index) => (
                      <li className="bullet_list" key={'fea'+index}>
                        <span className="check-icon ml-2 mr-2">✓</span>
                        {feature.text}
                      </li>
                    ))}
                  </ul>
                </div>
                <a
                  href={accountType.button_link[0].text}
                  className="btn btn-outline-secondary"
                >
                  {accountType.button_name[0].text}
                </a>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <SubscriptionPanel />
    </Layout>
  )
}

const mapStateToProps = state => ({ language: state.language })

export default connect(mapStateToProps)(AccountTypes)
